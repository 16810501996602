<div id="wrapper-navbar">
  

<div class="header-logo">
  <a routerLink="/homepage" routerLinkActive="active" >
  <img src="../assets/img/logo_pric.png" width="70">
  </a>
</div>    


<div class="navigation">
  <input type="checkbox" id="navi-toggle" class="navigation__checkbox" />

  <label for="navi-toggle" class="navigation__button">
    <span class="navigation__icon">
      <span class="navigation__icon-span">&nbsp;</span>
      <span class="navigation__icon-span">&nbsp;</span>
      <span class="navigation__icon-span">&nbsp;</span>
      <span class="navigation__icon-span">&nbsp;</span>
      <span class="navigation__icon-span">&nbsp;</span>
      <span class="navigation__icon-span">&nbsp;</span>
    </span>
  </label>

  <div class="navigation__background">&nbsp;</div>

  <nav class="navigation__nav">
    <ul class="navigation__list">
      <li class="navigation__item">
        <a class="navigation__link" routerLink="/homepage" routerLinkActive="active" >HOME</a>
      </li>
      <li class="navigation__item">
        <a class="navigation__link" routerLink="/about" routerLinkActive="active">ABOUT</a>
      </li>
      <li class="navigation__item">
        <a class="navigation__link" routerLink="/servizi" routerLinkActive="active">SERVICES</a>
      </li>
      <li class="navigation__item">
        <a class="navigation__link" routerLink="/portfolio" routerLinkActive="active">PORTFOLIO</a>
      </li>
      <li class="navigation__item">
        <a class="navigation__link" routerLink="/team" routerLinkActive="active">TEAM</a>
      </li>
      
    </ul>
  </nav>
</div>


</div>

<div class="super-cont" [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>