import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-mela',
  templateUrl: './mela.component.html',
  styleUrls: ['./mela.component.css']
})
export class MelaComponent implements OnInit {

  constructor() { }

  
  titGen = p.mela.titGen;
  txtGen = p.mela.txtGen;
  imgGen = p.mela.imgGen;
  dataGen = p.mela.dataGen;
  obbTxt = p.mela.obbTxt;
  lavTxt = p.mela.lavTxt;

  titSoc = p.mela.titSoc;
  txtSoc = p.mela.txtSoc;
  img1Soc = p.mela.img1Soc;
  img2Soc = p.mela.img2Soc;
  wallSoc = p.mela.wallSoc;

  titWeb = p.mela.titWeb;
  imgWeb = p.mela.imgWeb;
  dataWeb = p.mela.dataWeb;
  tit2Web = p.mela.tit2Web;



  img1App = p.mela.img1App;

  tit2App = p.mela.tit2App;
  txtApp = p.mela.txtApp;

  titBra = p.mela.titBra;
  img1Bra = p.mela.img1Bra;
  img2Bra = p.mela.img2Bra;
  tit2Bra = p.mela.tit2Bra;
  txtBra = p.mela.txtBra;

  tit3Bra = p.mela.tit3Bra;
  txt2Bra = p.mela.txt2Bra;


  img1Shoo = p.mela.Img1shoo;
  img2Shoo = p.mela.Img2shoo;
  img3Shoo = p.mela.Img3shoo;
  txtShoo = p.mela.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
