import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-cerqueti',
  templateUrl: './cerqueti.component.html',
  styleUrls: ['./cerqueti.component.css']
})
export class CerquetiComponent implements OnInit {

  constructor() { }

  
  titGen = p.cerqueti.titGen;
  txtGen = p.cerqueti.txtGen;
  imgGen = p.cerqueti.imgGen;
  dataGen = p.cerqueti.dataGen;
  obbTxt = p.cerqueti.obbTxt;
  lavTxt = p.cerqueti.lavTxt;

  titSoc = p.cerqueti.titSoc;
  txtSoc = p.cerqueti.txtSoc;
  img1Soc = p.cerqueti.img1Soc;
  img2Soc = p.cerqueti.img2Soc;
  wallSoc = p.cerqueti.wallSoc;

  titWeb = p.cerqueti.titWeb;
  imgWeb = p.cerqueti.imgWeb;
  dataWeb = p.cerqueti.dataWeb;
  tit2Web = p.cerqueti.tit2Web;



  img1App = p.cerqueti.img1App;

  tit2App = p.cerqueti.tit2App;
  txtApp = p.cerqueti.txtApp;

  titBra = p.cerqueti.titBra;
  img1Bra = p.cerqueti.img1Bra;
  img2Bra = p.cerqueti.img2Bra;
  tit2Bra = p.cerqueti.tit2Bra;
  txtBra = p.cerqueti.txtBra;

  tit3Bra = p.cerqueti.tit3Bra;
  txt2Bra = p.cerqueti.txt2Bra;


  img1Shoo = p.cerqueti.Img1shoo;
  img2Shoo = p.cerqueti.Img2shoo;
  img3Shoo = p.cerqueti.Img3shoo;
  txtShoo = p.cerqueti.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
