<div class="opacco row m-0 p-0 justify-content-center">
    <div class="col-md-8 row mx-0 mt-200 p-0">
        <div class="col-md-6">
            <h3>UN TEAM DI ALTO LIVELLO
                CON COMPETENZE
                DIVERSIFICATE</h3>
        </div>

        <div class="col-md-6">
            <h2>“Mettersi insieme è un
                inizio, rimanere insieme è
                un progresso, lavorare
                insieme un successo”</h2>
        </div>
    </div>

    <div class="col-md-8 row mx-0 mt-200 p-0 justify-content-center">
        <div class="col-6 col-md-3 my-5" *ngFor="let tmember of team1">
            <div class="c-glitch" style="background-image: url('../../assets/img/team/{{tmember.img}}');">
                <div class="c-glitch__img" style="background-image: url('../../assets/img/team/{{tmember.img}}');"></div>
                <div class="c-glitch__img" style="background-image: url('../../assets/img/team/{{tmember.img}}');"></div>
                <div class="c-glitch__img" style="background-image: url('../../assets/img/team/{{tmember.img}}');"></div>
                <div class="c-glitch__img" style="background-image: url('../../assets/img/team/{{tmember.img}}');"></div>
                <div class="c-glitch__img" style="background-image: url('../../assets/img/team/{{tmember.img}}');"></div>
            </div>
            <b>{{tmember.nome}} {{tmember.cognome}}</b><br />
            {{tmember.ruolo}}
        </div>

    </div>


<div class="footer-cont mt-200 w-100">

    <app-footer></app-footer>

</div></div>