import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutComponent } from './about/about.component';
import { TeamComponent} from './team/team.component';
import { ServiziComponent} from './servizi/servizi.component';
import { PortfolioComponent} from './portfolio/portfolio.component';

import { GaudenziComponent } from './portfolio/gaudenzi/gaudenzi.component'
import { AmadeusComponent } from './portfolio/amadeus/amadeus.component'
import { HappyCasaComponent } from './portfolio/happy-casa/happy-casa.component'
import { WisdomlessComponent } from './portfolio/wisdomless/wisdomless.component'
import { FumoComponent } from './portfolio/fumo/fumo.component'
import { PastBeddiniComponent } from './portfolio/past-beddini/past-beddini.component'
import { ApollinareComponent } from './portfolio/apollinare/apollinare.component'
import { GecomComponent } from './portfolio/gecom/gecom.component'
import { LeopardiComponent } from './portfolio/leopardi/leopardi.component'
import { TenBeddiniComponent } from './portfolio/ten-beddini/ten-beddini.component'
import { TitouchComponent } from './portfolio/titouch/titouch.component'
import { VdsComponent } from './portfolio/vds/vds.component'
import { BianchetoComponent } from './portfolio/biancheto/biancheto.component'
import { MelaComponent } from './portfolio/mela/mela.component'
import { AlecComponent } from './portfolio/alec/alec.component'
import { DecimiComponent } from './portfolio/decimi/decimi.component'
import { CerquetiComponent } from './portfolio/cerqueti/cerqueti.component'


const routes: Routes = [
  
{ path: '', component: HomepageComponent, data: {animation:'homepage'} },
{ path: 'homepage', component: HomepageComponent, data: {animation:'homepage'} },
{ path: 'about', component: AboutComponent, data: {animation:'contatti'} },
{ path: 'team', component: TeamComponent, data: {animation:'contatti'} },
{ path: 'servizi', component: ServiziComponent, data: {animation:'contatti'} },
{ path: 'portfolio', component: PortfolioComponent, data: {animation:'contatti'} },

{ path: 'gaudenzi', component: GaudenziComponent, data: {animation:'contatti'} },
{ path: 'amadeus', component: AmadeusComponent, data: {animation:'contatti'} },
{ path: 'hcasa', component: HappyCasaComponent, data: {animation:'contatti'} },
{ path: 'wisdomless', component: WisdomlessComponent, data: {animation:'contatti'} },
{ path: 'fumo', component: FumoComponent, data: {animation:'contatti'} },
{ path: 'beddini', component: PastBeddiniComponent, data: {animation:'contatti'} },
{ path: 'apollinare', component: ApollinareComponent, data: {animation:'contatti'} },
{ path: 'gecom', component: GecomComponent, data: {animation:'contatti'} },
{ path: 'leopardi', component: LeopardiComponent, data: {animation:'contatti'} },
{ path: 'tenutab', component: TenBeddiniComponent, data: {animation:'contatti'} },
{ path: 'titouch', component: TitouchComponent, data: {animation:'contatti'} },
{ path: 'valledelsole', component: VdsComponent, data: {animation:'contatti'} },
{ path: 'biancheto', component: BianchetoComponent, data: {animation:'contatti'} },
{ path: 'mela', component: MelaComponent, data: {animation:'contatti'} },
{ path: 'alec', component: AlecComponent, data: {animation:'contatti'} },
{ path: 'decimi', component: DecimiComponent, data: {animation:'contatti'} },
{ path: 'cerqueti', component: CerquetiComponent, data: {animation:'contatti'} },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
