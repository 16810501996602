import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-ten-beddini',
  templateUrl: './ten-beddini.component.html',
  styleUrls: ['./ten-beddini.component.css']
})
export class TenBeddiniComponent implements OnInit {

  constructor() { }

  
  titGen = p.tenbeddini.titGen;
  txtGen = p.tenbeddini.txtGen;
  imgGen = p.tenbeddini.imgGen;
  dataGen = p.tenbeddini.dataGen;
  obbTxt = p.tenbeddini.obbTxt;
  lavTxt = p.tenbeddini.lavTxt;

  titSoc = p.tenbeddini.titSoc;
  txtSoc = p.tenbeddini.txtSoc;
  img1Soc = p.tenbeddini.img1Soc;
  img2Soc = p.tenbeddini.img2Soc;
  wallSoc = p.tenbeddini.wallSoc;

  titWeb = p.tenbeddini.titWeb;
  imgWeb = p.tenbeddini.imgWeb;
  dataWeb = p.tenbeddini.dataWeb;
  tit2Web = p.tenbeddini.tit2Web;



  img1App = p.tenbeddini.img1App;

  tit2App = p.tenbeddini.tit2App;
  txtApp = p.tenbeddini.txtApp;

  titBra = p.tenbeddini.titBra;
  img1Bra = p.tenbeddini.img1Bra;
  img2Bra = p.tenbeddini.img2Bra;
  tit2Bra = p.tenbeddini.tit2Bra;
  txtBra = p.tenbeddini.txtBra;

  tit3Bra = p.tenbeddini.tit3Bra;
  txt2Bra = p.tenbeddini.txt2Bra;


  img1Shoo = p.tenbeddini.Img1shoo;
  img2Shoo = p.tenbeddini.Img2shoo;
  img3Shoo = p.tenbeddini.Img3shoo;
  txtShoo = p.tenbeddini.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}