import { Component } from '@angular/core';
import * as p from 'portfolio.json';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation, AnimationsComponent } from './animations/animations.component';
import {BackComponent} from './back/back.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'Priclab'; 

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  };
   
   bedTit = p.gaudenzi.dataGen;


  ngOnInit(){
    //console.log(data.beddini);
   // console.log(data.apollinare.id);
    



   
  }

 
}

function fumello( ) {
  var fumo =new BackComponent();
 }
  

  
 document.addEventListener('DOMContentLoaded', fumello);
