<div class="opacco port row m-0 p-0 justify-content-center">
    <div class="mt-200 col-md-7 row mx-0 px-0">
        <div data-aos="fade-right" class="col-md-6">
            <h1 class="titGen">{{titGen}}</h1>
            <p>{{txtGen}}</p>
        </div>
        <div data-aos="fade-left" class="col-md-6">
            <img *ngIf="imgGen!=''" src="../../../assets/img/portfolio/{{imgGen}}" class="w-100">
            <p class="dataGen"><b>{{dataGen}}</b></p>
        </div>

        <div data-aos="fade-right" class="col-md-6 mt-100">
            <h1 class="titGen">CLIENT<br /> GOALS</h1>

        </div>
        <div data-aos="fade-left" class="col-md-6 mt-100">
            <p>{{obbTxt}}</p>
        </div>
    </div>

    <div data-aos="fade-right" class="mt-200 col-md-7 row mx-0 px-0">
        <h1 class="col-md-7 titGen">OUR<br />WORK.</h1>
        <p class="col-md-7">{{lavTxt}}</p>
    </div>

    <div class=" col-md-7 row mx-0 px-0 mt-200" *ngIf="titBra!=''">
        <h1 data-aos="fade-right" class="col-md-10">{{titBra}}</h1>
        <div data-aos="fade-right" class="col-md-7 mt-100">
            <img class="w-100" src="../../../assets/img/portfolio/{{img1Bra}}">
            <h3 class="my-5">{{tit3Bra}}</h3>
            <p>{{txt2Bra}}</p>
        </div>
        <div data-aos="fade-left" class="col-md-5 ">
            <h3 class="my-5">{{tit2Bra}}</h3>
            <p>{{txtBra}}</p>
            <img *ngIf="img2Bra!=''" src="../../../assets/img/portfolio/{{img2Bra}}" class="w-100">

        </div>



    </div>


    <div class="mt-200 col-md-7 row mx-0 px-0" *ngIf="titWeb!=''">
        <div class="col-md-12">
            <h1 data-aos="fade-right" class="titGen">{{titWeb}}</h1>


        </div>
        <div *ngIf="imgWeb!=''" data-aos="fade-up" class=" desk ">
            <img  class="" src="../../../assets/img/portfolio/{{imgWeb}}">
            <!--<video  autoplay>
                <source src="../../../assets/img/portfolio/gaudenzi_desktop.mp4" type="video/mp4">
               
                Your browser does not support the video tag.
              </video> -->
        </div>
        <div data-aos="fade-right" class="row mx-0 px-0 col-md-6">

            
            <div *ngIf="img1App!=''" class="col-md-6 mt-100 mob"> 
                <div class="scroll">
                <img  src="../../../assets/img/portfolio/{{img1App}}" ></div>
            </div>
        </div>

        <div data-aos="fade-left" class="col-md-6 mt-200">
            <h3>{{tit2App}}</h3>
            <p>{{txtApp}}</p>
        </div>

    </div>



    <div class="mt-200 col-md-7 row mx-0 px-0" *ngIf="titSoc!=''">
        <div data-aos="fade-right" class="col-md-6">
            <h1 class="titGen">{{titSoc}}</h1>
            <img class="w-100" src="../../../assets/img/portfolio/{{img1Soc}}">
           
            <p class="mt-5">{{txtSoc}}</p>
        </div>
        <div *ngIf="img2Soc!=''" data-aos="fade-left" class="col-md-6">
            <img class="mt-200 w-100" src="../../../assets/img/portfolio/{{img2Soc}}">
           
        </div>
    </div>


    <div class="mt-200 col-md-7 row mx-0 px-0" *ngIf="img1Shoo!=''">
        <h1 class="titGen col-md-10">PHOTO<br />SHOOTING</h1>
        <div data-aos="fade-right" class="col-md-4">

            <img class="grow " src="../../../assets/img/portfolio/{{img1Shoo}}">

        </div>
        <div data-aos="fade-up " class="col-md-4">
            <img class="mt-100 grow " src="../../../assets/img/portfolio/{{img2Shoo}}">
        </div>
        <div data-aos="fade-left" class="col-md-4">
            <img class="grow mt-200" src="../../../assets/img/portfolio/{{img3Shoo}}">
        </div>
        <p class="mt-5">{{txtShoo}}</p>
    </div>






    <div class="col-md-7 d-block d-md-none back text-center mt-100">
        <a routerLink="/portfolio" routerLinkActive="active">
            <h1>←</h1>
        </a>
    </div>

    <div class=" d-none d-md-block back back-d">
        <a routerLink="/portfolio" routerLinkActive="active">
            <h1>←</h1>
        </a>
    </div>




    <div class="footer-cont mt-200 w-100">

        <app-footer></app-footer>

    </div>
</div>