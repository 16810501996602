import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-titouch',
  templateUrl: './titouch.component.html',
  styleUrls: ['./titouch.component.css']
})
export class TitouchComponent implements OnInit {

 
  constructor() { }

  
  titGen = p.titouch.titGen;
  txtGen = p.titouch.txtGen;
  imgGen = p.titouch.imgGen;
  dataGen = p.titouch.dataGen;
  obbTxt = p.titouch.obbTxt;
  lavTxt = p.titouch.lavTxt;

  titSoc = p.titouch.titSoc;
  txtSoc = p.titouch.txtSoc;
  img1Soc = p.titouch.img1Soc;
  img2Soc = p.titouch.img2Soc;
  wallSoc = p.titouch.wallSoc;

  titWeb = p.titouch.titWeb;
  imgWeb = p.titouch.imgWeb;
  dataWeb = p.titouch.dataWeb;
  tit2Web = p.titouch.tit2Web;



  img1App = p.titouch.img1App;

  tit2App = p.titouch.tit2App;
  txtApp = p.titouch.txtApp;

  titBra = p.titouch.titBra;
  img1Bra = p.titouch.img1Bra;
  img2Bra = p.titouch.img2Bra;
  tit2Bra = p.titouch.tit2Bra;
  txtBra = p.titouch.txtBra;

  tit3Bra = p.titouch.tit3Bra;
  txt2Bra = p.titouch.txt2Bra;


  img1Shoo = p.titouch.Img1shoo;
  img2Shoo = p.titouch.Img2shoo;
  img3Shoo = p.titouch.Img3shoo;
  txtShoo = p.titouch.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
