import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-gaudenzi',
  templateUrl: './gaudenzi.component.html',
  styleUrls: ['./gaudenzi.component.css']
})
export class GaudenziComponent implements OnInit {

  constructor() { }

  titGen = p.gaudenzi.titGen;
  txtGen = p.gaudenzi.txtGen;
  imgGen = p.gaudenzi.imgGen;
  dataGen = p.gaudenzi.dataGen;
  obbTxt = p.gaudenzi.obbTxt;
  lavTxt = p.gaudenzi.lavTxt;

  titSoc = p.gaudenzi.titSoc;
  txtSoc = p.gaudenzi.txtSoc;
  img1Soc = p.gaudenzi.img1Soc;
  img2Soc = p.gaudenzi.img2Soc;
  wallSoc = p.gaudenzi.wallSoc;

  titWeb = p.gaudenzi.titWeb;
  imgWeb = p.gaudenzi.imgWeb;
  dataWeb = p.gaudenzi.dataWeb;
  tit2Web = p.gaudenzi.tit2Web;



  img1App = p.gaudenzi.img1App;

  tit2App = p.gaudenzi.tit2App;
  txtApp = p.gaudenzi.txtApp;

  titBra = p.gaudenzi.titBra;
  img1Bra = p.gaudenzi.img1Bra;
  img2Bra = p.gaudenzi.img2Bra;
  tit2Bra = p.gaudenzi.tit2Bra;
  txtBra = p.gaudenzi.txtBra;

  tit3Bra = p.gaudenzi.tit3Bra;
  txt2Bra = p.gaudenzi.txt2Bra;


  img1Shoo = p.gaudenzi.Img1shoo;
  img2Shoo = p.gaudenzi.Img2shoo;
  img3Shoo = p.gaudenzi.Img3shoo;
  txtShoo = p.gaudenzi.Txtshoo;

  ngOnInit(): void {


    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
 
  }

}
