import { Component, OnInit } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'node_modules/three-orbitcontrols'
import { Water } from './Water2.js'
import { from } from 'rxjs';

@Component({
	selector: 'app-back',
	templateUrl: './back.component.html',
	styleUrls: ['./back.component.css']
})
export class BackComponent implements OnInit {

	constructor() {


		var scene = new THREE.Scene();

		// camera

		var camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 200);
		camera.position.set(0, 10, 0);
		camera.lookAt(scene.position);

		// ground

		const groundGeometry = new THREE.PlaneBufferGeometry(20, 20, 10, 10);
		const groundMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });
		const ground = new THREE.Mesh(groundGeometry, groundMaterial);
		ground.rotation.x = Math.PI * - 0.5;
		scene.add(ground);

		const textureLoader = new THREE.TextureLoader();
		textureLoader.load('../../../assets/img/background_8.jpg', function (map) {

			map.wrapS = THREE.RepeatWrapping;
			map.wrapT = THREE.RepeatWrapping;
			map.anisotropy = 16;
			map.repeat.set(1, 1);
			groundMaterial.map = map;
			groundMaterial.needsUpdate = true;

		});

		// water

		const waterGeometry = new THREE.PlaneBufferGeometry(20, 20);
		const flowMap = textureLoader.load('../../../assets/img/Water_1_M_Flow.jpg');

		var water = new Water(waterGeometry, {
			scale: 2,
			textureWidth: 1024,
			textureHeight: 1024,
			flowMap: flowMap
		});

		water.position.y = 1;
		water.rotation.x = Math.PI * - 0.5;
		scene.add(water);

		// flow map helper

		const helperGeometry = new THREE.PlaneBufferGeometry(20, 20);
		const helperMaterial = new THREE.MeshBasicMaterial({ map: flowMap });
		const helper = new THREE.Mesh(helperGeometry, helperMaterial);
		helper.position.y = 1.01;
		helper.rotation.x = Math.PI * - 0.5;
		helper.visible = false;
		scene.add(helper);

		// renderer

		var renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setPixelRatio(window.devicePixelRatio);
		document.body.appendChild(renderer.domElement);

		//
		/*
			const gui = new GUI();
			gui.add( helper, 'visible' ).name( 'Show Flow Map' );
			gui.open();
		*/
		//
/*
		const controls = new OrbitControls(camera, renderer.domElement);
		controls.minDistance = 5;
		controls.maxDistance = 50;
*/
		//

		animate();
		window.addEventListener('resize', onResize, false);

	

function onResize() {

	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize(window.innerWidth, window.innerHeight);

}

function animate() {

	requestAnimationFrame(animate);

	render();

}

function render() {

	renderer.render(scene, camera);

}

  }

ngOnInit(): void {
}

}
