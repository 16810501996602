import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-past-beddini',
  templateUrl: './past-beddini.component.html',
  styleUrls: ['./past-beddini.component.css']
})
export class PastBeddiniComponent implements OnInit {

 
  constructor() { }

  
  titGen = p.beddini.titGen;
  txtGen = p.beddini.txtGen;
  imgGen = p.beddini.imgGen;
  dataGen = p.beddini.dataGen;
  obbTxt = p.beddini.obbTxt;
  lavTxt = p.beddini.lavTxt;

  titSoc = p.beddini.titSoc;
  txtSoc = p.beddini.txtSoc;
  img1Soc = p.beddini.img1Soc;
  img2Soc = p.beddini.img2Soc;
  wallSoc = p.beddini.wallSoc;

  titWeb = p.beddini.titWeb;
  imgWeb = p.beddini.imgWeb;
  dataWeb = p.beddini.dataWeb;
  tit2Web = p.beddini.tit2Web;



  img1App = p.beddini.img1App;

  tit2App = p.beddini.tit2App;
  txtApp = p.beddini.txtApp;

  titBra = p.beddini.titBra;
  img1Bra = p.beddini.img1Bra;
  img2Bra = p.beddini.img2Bra;
  tit2Bra = p.beddini.tit2Bra;
  txtBra = p.beddini.txtBra;

  tit3Bra = p.beddini.tit3Bra;
  txt2Bra = p.beddini.txt2Bra;


  img1Shoo = p.beddini.Img1shoo;
  img2Shoo = p.beddini.Img2shoo;
  img3Shoo = p.beddini.Img3shoo;
  txtShoo = p.beddini.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
