import { Component, OnInit } from '@angular/core';
import {TMember} from 'src/app/tmember'


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['../../glitch.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }

  team1 = [
    new TMember('Gabriele','Porfiri','CEO Priclab', 'gabriele.jpg'),
    new TMember('Pasquale','Romano','CFO Priclab', 'passi2.png'),
    new TMember('Francesco','Marcantonini','Sales Director', 'marcantonini.jpg'),
    new TMember('Silvia','Trampetti','Sales Manager', 'silvia.jpg'),
    new TMember('Massimiliano','Ciancaleoni','Project Manager', 'massi.jpg'),
    new TMember('Francesco','Proietti Lippi','Digital Development Director', 'lippi.jpg'),
    new TMember('Andrea','Consalvi','Digital Marketing & Social Media Manager Director', 'andrea.jpg'),
    //new TMember('Sara','Pacioselli','Social Media Manager', 'sara.jpg'),
    new TMember('Francesco','Cardillo','Creative DIrector', 'cardillo.jpg'),
    new TMember('Giovanna','Vedovati','Content Marketing Manager', 'giovanna.jpg'),
    new TMember('Cristiana','Raneri','Visual Designer', 'cristiana.jpg'),
    new TMember('Martina','Tasca','Illustrator & Cartoonist', 'martin.jpg'),
    new TMember('Martina','Paliani','Art DIrector & Set Designer', 'paliani.jpg'),
    new TMember('Denny','Mosconi','Photographer', 'denny.jpg'),


  ]


  ngOnInit(): void {

    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
 




  }

}
