<div class="opacco about row m-0 p-0 justify-content-center">
    <div class="col-md-8 row mx-0 mt-200 p-0">
        <div  class="col-md-6">
            <h2>PRIC LAB: OUR<br /> TWO SOULS<br /> ALREADY IN THE<br /> NAME.</h2>
        </div>
        <div class="col-md-6">
            <p>
                <b>Pric</b> like PR International Consulting: advanced
                management and marketing consultancy to know
                and understand the market trends in which we

                operate
                and exploit them for the benefit of our customers.<br /><br />

                <b>Lab</b>, as a laboratory: a constantly active breeding
                ground for talents with multiple specializations
                (communication, graphic design, IT, ...), to provide you
                with the ideal creativity to convey your business
                message.
            </p>
        </div>
    </div>

    <div class="col-md-8 row mx-0 mt-100 p-0">
        <div  class="col-md-6 hww">
            <h1 class="glitch" data-text="HOW">HOW</h1>
            <h1 class="glitch" data-text="WE">WE</h1>
            <h1 class="glitch" data-text="WORK">WORK</h1>

            <h2 data-aos="fade-right">“It is not only necessary
                to be always up to
                date on new trends,
                but to create them.”</h2>
        </div>

        <div data-aos="fade-left" class="col-md-6 mt-200">
            <h3>A single supply chain
                is the best way to
                create effective and
                credible
                communication.</h3>

                <p>From the business plan to the study of the most
                    suitable strategy for you. From <b>brand identity, to
                    packaging, from offline communication to online,
                    from content to digital marketing campaigns,</b> from
                    the creation and production of <b>video to sound,</b> from
                    the development of websites to the development of
                    new products and technologies tailored for you.
                <br/> <br/>
                    
                    Everything happens under one roof, designed by a
                    team of specialists. We never lose sight of your
                    needs. <br/> <br/>
                    
                    We are full service and at your complete
                    disposal. We were born with a look to the future.</p>
        </div>

    </div>
    <div data-aos="fade-up" class="col-md-5 text-center">
        <h2>WHEN STARTING A NEW PROJECT,

            WE START FROM CONSULTING AND
            
            STUDYING THE DATA ABOUT YOUR
            
            BRAND.</h2>
    </div>
    <!--
    <div class="col-md-8 row mx-0 mt-100 p-0">
        <div data-aos="fade-right" class="col-md-6">
            <h1 class="glitch" data-text="MISS">MISS</h1>
            <h1 class="glitch" data-text="ION.">ION.</h1>

        </div>

        <div class="col-md-6">
            <h3 data-aos="fade-left">PricLab è un team di
                esperti in Sviluppo
                Business e Marketing.</h3>

            <p data-aos="fade-left">La <b>Passione</b> per il nostro lavoro ci fa credere che la
                strategia di business vincente esiste per tutti, anche
                per le realtà emergenti. Le nostre <b>Competenze
                professionali</b> ci aiutano a realizzare piani strategici
                mirati ad incrementare il business di diverse tipologie
                di attività, creando nuove opportunità per i brand.<br/><br/>
                
                Dalla <b>Collaborazione</b> e dalla sinergia fra le menti
                creative del nostro team, nascono progetti unici con
                strategie a breve, medio e lungo termine.</p>
        </div>
    </div>


    <div class="col-md-8 row mx-0 mt-100 p-0">
        <div data-aos="fade-right" class="col-md-6 mb-5">
            <h1 class="glitch" data-text="VISI">VISI</h1>
            <h1 class="glitch" data-text="ON.">ON.</h1>
            <img class="w-100" src="../../assets/img/photo2_ABOUT.png">
            <div class="gab">
                <b>Gabriele Porfiri</b><br/>
                CEO Priclab

            </div>
            <div class="pas">
                <b>Pasquale Romano</b><br/>
                CEO Priclab

            </div>
        </div>

        <div class="col-md-6">
           

            <p data-aos="fade-left">Vogliamo costruire un ecosistema basato su principi
                di lavoro sani, diversificato nelle competenze, con
                una vision che guardi oltre l’economia, dove al centro
                ci sono le persone, la loro realizzazione personale e la
                felicità. Un ambiente pronto ad ogni sfida, dove
                ognuno possa offrire il massimo di sé andando oltre
                la proposta del singolo servizio o strumento.
                
                Siamo convinti che un team coeso e contraddistinto
                da competenze, volontà e consapevolezza del
                proprio lavoro, aiuti a mettere al centro
                dell’attenzione il cliente e i suoi obiettivi. Nel nostro
                mondo coesistono manager e artisti, in modo da
                incrociare punti di vista differenti per arrivare a visioni
                uniche ed inimitabili.</p>
        </div>
    </div>
-->



<div class="footer-cont mt-200 w-100">

    <app-footer></app-footer>

</div></div>