import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-amadeus',
  templateUrl: './amadeus.component.html',
  styleUrls: ['./amadeus.component.css']
})
export class AmadeusComponent implements OnInit {

  constructor() { }

  
  titGen = p.amadeus.titGen;
  txtGen = p.amadeus.txtGen;
  imgGen = p.amadeus.imgGen;
  dataGen = p.amadeus.dataGen;
  obbTxt = p.amadeus.obbTxt;
  lavTxt = p.amadeus.lavTxt;

  titSoc = p.amadeus.titSoc;
  txtSoc = p.amadeus.txtSoc;
  img1Soc = p.amadeus.img1Soc;
  img2Soc = p.amadeus.img2Soc;
  wallSoc = p.amadeus.wallSoc;

  titWeb = p.amadeus.titWeb;
  imgWeb = p.amadeus.imgWeb;
  dataWeb = p.amadeus.dataWeb;
  tit2Web = p.amadeus.tit2Web;



  img1App = p.amadeus.img1App;

  tit2App = p.amadeus.tit2App;
  txtApp = p.amadeus.txtApp;

  titBra = p.amadeus.titBra;
  img1Bra = p.amadeus.img1Bra;
  img2Bra = p.amadeus.img2Bra;
  tit2Bra = p.amadeus.tit2Bra;
  txtBra = p.amadeus.txtBra;

  tit3Bra = p.amadeus.tit3Bra;
  txt2Bra = p.amadeus.txt2Bra;


  img1Shoo = p.amadeus.Img1shoo;
  img2Shoo = p.amadeus.Img2shoo;
  img3Shoo = p.amadeus.Img3shoo;
  txtShoo = p.amadeus.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
