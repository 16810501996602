<div class="row m-0 p-0  justify-content-center ">

    <div class="col-6 col-centered footer-divider"></div>


    <div class="col-md-8 row m-0 p-0 my-5 justify-content-center">
        <div class="col-md-3 text-center text-md-left">
            <p>info@priclab.com<br />
                Via Enrico Fermi 12/B, 20097 San Donato Milanese (MI)</p>
        </div>

        <div class="col-md-6 row p-0 m-0">
            <div class="col-md-4 text-center"><a href="https://www.facebook.com/PricLab">FACEBOOK</a></div>
            <div class="col-md-4 text-center"><a href="https://www.instagram.com/pric_lab/">INSTAGRAM</a></div>
            <div class="col-md-4 text-center"><a href="https://www.linkedin.com/company/priclab/">LINKEDIN</a></div>
        </div>

        <div class="col-md-3 footer-center text-center ">
            <p>Tel: 0742 267222</p>
           
        </div>
    </div>
</div>