<h2 class="serv-supertit">OUR PLUSES.</h2>
<div class=" serv opacco row m-0 p-0 container-slider justify-content-center">
    <div class="slider slider-serv col-md-8">
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="TECH.">TECH.</h1>
                </div>
                <div class="col-md-6">
                    <p> Our team is specialized in <b>Software
                            development</b>, with particular attention to
                        the development of the most recent and
                        innovative technologies such as<b>
                            Augmented Reality, Artificial Intelligence,
                            Mobile App, CMS.</b></p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="STRA">STRA</h1>
                    <h1 class="ser-tit glitch" data-text="TEGY.">TEGY.</h1>
                </div>
                <div class="col-md-6">
                    <p> We use the <b>Business Model Canvas</b>, a
                        system that helps us to divide the
                        strengths and critical points of your
                        company and integrate the objectives it
                        wants to achieve in a predetermined time.
                        This analysis help us to <b>develop a suitable
                            strategy for the customer.</b></p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="SPOT">SPOT</h1>
                    <h1 class="ser-tit glitch" data-text="AND">AND</h1>
                    <h1 class="ser-tit glitch" data-text="VIDEO">VIDEO.</h1>
                </div>
                <div class="col-md-6">
                    <p> Our <b>Audiovisual</b> team deals with the
                        creation, realization and finalization of
                        <b>video clips, back-stage, commercials,
                            short films, interviews, show-reels, filming
                            of sporting events, shows, conferences,
                            fashion shows.</b>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="PHOTO">PHOTO</h1>
                    <h1 class="ser-tit glitch" data-text="SHO">SHO</h1>
                    <h1 class="ser-tit glitch" data-text="OTING.">OTING.</h1>
                </div>
                <div class="col-md-6">
                    <p>Our team is formed by <b>Fashion
                            Photographers and food photographers
                            with a high-level portfolio.</b></p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="SEO">SEO</h1>
                    <h1 class="ser-tit glitch" data-text="AND">AND</h1>
                    <h1 class="ser-tit glitch" data-text="SEM.">SEM.</h1>
                </div>
                <div class="col-md-6">
                    <p>Using <b>data analysis</b> that all digital
                        marketing tools are able to collect,
                        our team will develop the most
                        <b>effective strategy</b> to promote your
                        business in the world of <b>search engines.</b>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="DES">DES</h1>
                    <h1 class="ser-tit glitch" data-text="IGN.">IGN.</h1>

                </div>
                <div class="col-md-6">
                    <p>Our goal is your uniqueness. <b>The
                            Graphic team</b> “marries” your brand and
                        works to create or strengthen <b>your
                            identity and your value.</b> You talking to
                        the world is our goal.</p>
                </div>
            </div>
        </div>
        <div>
            <div class="row serv-cont">
                <div class="col-md-6">
                    <h1 class="ser-tit glitch" data-text="COPY">COPY</h1>
                    <h1 class="ser-tit glitch" data-text="WRITI">WRITI</h1>
                    <h1 class="ser-tit glitch" data-text="NG.">NG.</h1>
                </div>
                <div class="col-md-6">
                    <p> Our <b>Audiovisual</b> team deals with the
                        creation, realization and finalization of
                        <b>video clips, back-stage, commercials,
                            short films, interviews, show-reels, filming
                            of sporting events, shows, conferences,
                            fashion shows.</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>