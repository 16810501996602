import { Component, OnInit } from '@angular/core';

import {Port} from 'src/app/port'


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() {}
    
    lavori =[
      new Port('1','Frantoio','Gaudenzi','gaudenzi','mask="url(#mask1)"'),
      new Port('2','Franto','Gauden','gaudenzi','mask="url(#mask2)"'),
      new Port('3','Frantoio3','Gaudenzi3','gaudenzi','mask="url(#mask3)"')
    ]


  


ngOnInit(): void {

  window.scrollTo(0, 0);

  var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
  menu.checked = false;
}
 
  }

}
