<div class="row m-0 p-0 home container-slider justify-content-center">
    <div class="slider slider-home d-none d-md-flex">


        <div>
            <a routerLink="/about" routerLinkActive="active">
                <h1 class="glitch" data-text="ABOUT">ABOUT</h1>
            </a>
        </div>
        <div>
            <a routerLink="/servizi" routerLinkActive="active">
                <h1 class="glitch" data-text="SERVICES">SERVICES</h1>
            </a>
        </div>
        <div>
            <a routerLink="/portfolio" routerLinkActive="active">
                <h1 class="glitch" data-text="PORTFOLIO">PORTFOLIO</h1>
            </a>
        </div>
        <div>
            <a routerLink="/team" routerLinkActive="active">
                <h1 class="glitch" data-text="TEAM">TEAM</h1>
            </a>
        </div>

    </div>







    <div class="slider slider-home d-flex d-md-none">


        <div>
            <a routerLink="/about" routerLinkActive="active">
                <h1 class="glitch" data-text="AB">AB</h1>
                <h1 class="glitch" data-text="OUT">OUT</h1>
            </a>
        </div>
        <div>
            <a routerLink="/servizi" routerLinkActive="active">
                <h1 class="glitch" data-text="SERV">SERV</h1>
                <h1 class="glitch" data-text="ICES">ICES</h1>
            </a>
        </div>
        <div class="d-md-flex">
            <a routerLink="/portfolio" routerLinkActive="active">
                <h1 class="glitch" data-text="PORT">PORT</h1>
                <h1 class="glitch" data-text="FOLIO">FOLIO</h1>
            </a>
        </div>
        <div>
            <a routerLink="/team" routerLinkActive="active">
                <h1 class="glitch" data-text="TE">TE</h1>
                <h1 class="glitch" data-text="AM">AM</h1>
            </a>
        </div>

    </div>
</div>
