import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-gecom',
  templateUrl: './gecom.component.html',
  styleUrls: ['./gecom.component.css']
})
export class GecomComponent implements OnInit {

  constructor() { }

  
  titGen = p.gecom.titGen;
  txtGen = p.gecom.txtGen;
  imgGen = p.gecom.imgGen;
  dataGen = p.gecom.dataGen;
  obbTxt = p.gecom.obbTxt;
  lavTxt = p.gecom.lavTxt;

  titSoc = p.gecom.titSoc;
  txtSoc = p.gecom.txtSoc;
  img1Soc = p.gecom.img1Soc;
  img2Soc = p.gecom.img2Soc;
  wallSoc = p.gecom.wallSoc;

  titWeb = p.gecom.titWeb;
  imgWeb = p.gecom.imgWeb;
  dataWeb = p.gecom.dataWeb;
  tit2Web = p.gecom.tit2Web;



  img1App = p.gecom.img1App;

  tit2App = p.gecom.tit2App;
  txtApp = p.gecom.txtApp;

  titBra = p.gecom.titBra;
  img1Bra = p.gecom.img1Bra;
  img2Bra = p.gecom.img2Bra;
  tit2Bra = p.gecom.tit2Bra;
  txtBra = p.gecom.txtBra;

  tit3Bra = p.gecom.tit3Bra;
  txt2Bra = p.gecom.txt2Bra;


  img1Shoo = p.gecom.Img1shoo;
  img2Shoo = p.gecom.Img2shoo;
  img3Shoo = p.gecom.Img3shoo;
  txtShoo = p.gecom.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
