import { Component, OnInit } from '@angular/core';
import {
  animation, trigger, animateChild, group,
  transition, animate, style, query
} from '@angular/animations';


@Component({
  selector: 'app-animations',
  templateUrl: './animations.component.html',
  styleUrls: ['./animations.component.css']
})
export class AnimationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], { optional: true }),
      query(':enter', [
        style({
          opacity: '0'
        })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),

      group([
        query(':leave', [
          animate('1000ms ease-out', style({ opacity: '0' }))
        ], { optional: true }),
        query(':enter', [
          animate('1000ms ease-out', style({ opacity: '1' }))
        ], { optional: true })
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),







    transition('* <=> contatti', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ top: '-10%', opacity: '0' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('1000ms ease-out', style({opacity: '0' }))
        ]),
        query(':enter', [
          animate('1000ms ease-out', style({ opacity: '1'}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);