import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-happy-casa',
  templateUrl: './happy-casa.component.html',
  styleUrls: ['./happy-casa.component.css']
})
export class HappyCasaComponent implements OnInit {

  constructor() { }

  
  titGen = p.hcasa.titGen;
  txtGen = p.hcasa.txtGen;
  imgGen = p.hcasa.imgGen;
  dataGen = p.hcasa.dataGen;
  obbTxt = p.hcasa.obbTxt;
  lavTxt = p.hcasa.lavTxt;

  titSoc = p.hcasa.titSoc;
  txtSoc = p.hcasa.txtSoc;
  img1Soc = p.hcasa.img1Soc;
  img2Soc = p.hcasa.img2Soc;
  wallSoc = p.hcasa.wallSoc;

  titWeb = p.hcasa.titWeb;
  imgWeb = p.hcasa.imgWeb;
  dataWeb = p.hcasa.dataWeb;
  tit2Web = p.hcasa.tit2Web;



  img1App = p.hcasa.img1App;

  tit2App = p.hcasa.tit2App;
  txtApp = p.hcasa.txtApp;

  titBra = p.hcasa.titBra;
  img1Bra = p.hcasa.img1Bra;
  img2Bra = p.hcasa.img2Bra;
  tit2Bra = p.hcasa.tit2Bra;
  txtBra = p.hcasa.txtBra;

  tit3Bra = p.hcasa.tit3Bra;
  txt2Bra = p.hcasa.txt2Bra;


  img1Shoo = p.hcasa.Img1shoo;
  img2Shoo = p.hcasa.Img2shoo;
  img3Shoo = p.hcasa.Img3shoo;
  txtShoo = p.hcasa.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
