import { Component, OnInit } from '@angular/core';




declare var $: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }





  ngOnInit(): void {









    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
    if (menu.checked == true) {
      menu.checked = false;
    }




    var slider = $('.slider-home').slick({
      slidesToShow: 3,

      slidesToScroll: 1,

      arrows: false,

      fade: false,

      autoplay: true,

      cssEase: 'ease-out',

      pauseOnHover: false,
      pauseOnFocus: false,

      verticalSwiping: true,

      centerMode: true,

      vertical: true,


      responsive: [

        {

          breakpoint: 1024,

          settings: {

            slidesToShow: 1,

            slidesToScroll: 1,

            infinite: true



          }

        },

        {

          breakpoint: 600,

          settings: {

            slidesToShow: 3,

            slidesToScroll: 1

          }

        }

      ]
    });







/*

     $('.back-slider-home').slick({
      slidesToShow: 1,

      slidesToScroll: 1,

      arrows: false,

      fade: false,

      autoplay: true,

      cssEase: 'ease-out',

      pauseOnHover: false,
      pauseOnFocus: false,

      

      centerMode: false,

      vertical: true,


      asNavFor: '.slider-home',

      responsive: [

        {

          breakpoint: 1024,

          settings: {

            slidesToShow: 1,

            slidesToScroll: 1,

            infinite: true



          }

        },

        {

          breakpoint: 600,

          settings: {

            slidesToShow: 3,

            slidesToScroll: 1

          }

        }

      ]
    });

*/





   /* slider.on('wheel', (function (e) {
      e.preventDefault(); if (e.originalEvent.deltaY < 0) {
        $(this).slick('slickNext');
      } else {
        $(this).slick('slickPrev');
      }
    }));
*/


    let blocked = false;
let blockTimeout = null;
let prevDeltaY = 0;

slider.on('mousewheel DOMMouseScroll wheel', (function(e) {
    let deltaY = e.originalEvent.deltaY;
    e.preventDefault();
    e.stopPropagation();

    clearTimeout(blockTimeout);
    blockTimeout = setTimeout(function(){
        blocked = false;
    }, 50);

    
    if (deltaY > 0 && deltaY > prevDeltaY || deltaY < 0 && deltaY < prevDeltaY || !blocked) {
        blocked = true;
        prevDeltaY = deltaY;

        if (deltaY > 0) {
            $(this).slick('slickNext');
        } else {
            $(this).slick('slickPrev');
        }
    }
}));

  }



}
