import { Component, OnInit } from '@angular/core';
import * as p from 'portfolio.json';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-decimi',
  templateUrl: './decimi.component.html',
  styleUrls: ['./decimi.component.css']
})
export class DecimiComponent implements OnInit {

  constructor() { }

  
  titGen = p.decimi.titGen;
  txtGen = p.decimi.txtGen;
  imgGen = p.decimi.imgGen;
  dataGen = p.decimi.dataGen;
  obbTxt = p.decimi.obbTxt;
  lavTxt = p.decimi.lavTxt;

  titSoc = p.decimi.titSoc;
  txtSoc = p.decimi.txtSoc;
  img1Soc = p.decimi.img1Soc;
  img2Soc = p.decimi.img2Soc;
  wallSoc = p.decimi.wallSoc;

  titWeb = p.decimi.titWeb;
  imgWeb = p.decimi.imgWeb;
  dataWeb = p.decimi.dataWeb;
  tit2Web = p.decimi.tit2Web;



  img1App = p.decimi.img1App;

  tit2App = p.decimi.tit2App;
  txtApp = p.decimi.txtApp;

  titBra = p.decimi.titBra;
  img1Bra = p.decimi.img1Bra;
  img2Bra = p.decimi.img2Bra;
  tit2Bra = p.decimi.tit2Bra;
  txtBra = p.decimi.txtBra;

  tit3Bra = p.decimi.tit3Bra;
  txt2Bra = p.decimi.txt2Bra;


  img1Shoo = p.decimi.Img1shoo;
  img2Shoo = p.decimi.Img2shoo;
  img3Shoo = p.decimi.Img3shoo;
  txtShoo = p.decimi.Txtshoo;


  ngOnInit(): void {

    AOS.init({
      duration: 3000,
      anchorPlacement: 'top-bottom',
    });
    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
  if(menu.checked == true){
    menu.checked = false;
  }
  }

}
