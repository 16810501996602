import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AnimationsComponent } from './animations/animations.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';


import { HomepageComponent } from './homepage/homepage.component';
import { ServiziComponent } from './servizi/servizi.component';
import { TeamComponent } from './team/team.component';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { GaudenziComponent } from './portfolio/gaudenzi/gaudenzi.component';
import { BackComponent } from './back/back.component';

import { FooterComponent } from './footer/footer.component';
import { AmadeusComponent } from './portfolio/amadeus/amadeus.component';
import { HappyCasaComponent } from './portfolio/happy-casa/happy-casa.component';
import { WisdomlessComponent } from './portfolio/wisdomless/wisdomless.component';
import { FumoComponent } from './portfolio/fumo/fumo.component';
import { PastBeddiniComponent } from './portfolio/past-beddini/past-beddini.component';
import { ApollinareComponent } from './portfolio/apollinare/apollinare.component';
import { GecomComponent } from './portfolio/gecom/gecom.component';
import { LeopardiComponent } from './portfolio/leopardi/leopardi.component';
import { TenBeddiniComponent } from './portfolio/ten-beddini/ten-beddini.component';
import { TitouchComponent } from './portfolio/titouch/titouch.component';
import { VdsComponent } from './portfolio/vds/vds.component';
import { BianchetoComponent } from './portfolio/biancheto/biancheto.component';
import { MelaComponent } from './portfolio/mela/mela.component';
import { AlecComponent } from './portfolio/alec/alec.component';
import { DecimiComponent } from './portfolio/decimi/decimi.component';
import { CerquetiComponent } from './portfolio/cerqueti/cerqueti.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ServiziComponent,
    TeamComponent,
    AboutComponent,
    PortfolioComponent,
    GaudenziComponent,
    AnimationsComponent,
    BackComponent,
    FooterComponent,
    AmadeusComponent,
    HappyCasaComponent,
    WisdomlessComponent,
    FumoComponent,
    PastBeddiniComponent,
    ApollinareComponent,
    GecomComponent,
    LeopardiComponent,
    TenBeddiniComponent,
    TitouchComponent,
    VdsComponent,
    BianchetoComponent,
    MelaComponent,
    AlecComponent,
    DecimiComponent,
    CerquetiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
