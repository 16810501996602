<div class="opacco row m-0 p-0 justify-content-center">
    <div class="mt-200 col-md-7 row mx-0 px-0">

        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/amadeus" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/list_AMADEUS.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask1" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                           
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">AMADEUS</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask1)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>




        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/gaudenzi" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_GAUDENZI.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask2" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">FRANTOIO</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">GAUDENZI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask2)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>



        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/hcasa" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/photo3_HC.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask3" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">HAPPY</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">CASA</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask3)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>




        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/wisdomless" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/list_WISDOMLESS.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask4" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">WISDOMLESS</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">CLUB</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask4)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>





        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/fumo" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/shooting2_FUMO.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask5" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">FEDERICO</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">FUMO</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask5)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>



        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/beddini" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_PASTICCERIABEDDINI.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask6" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">PASTICCERIA</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">BEDDINI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask6)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>




        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/apollinare" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/list_APOLLINARE.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask7" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">RISTORANTE</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">APOLLINARE</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask7)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>



        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/gecom" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/list_GECOM.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask8" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">GECOM</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask8)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>


        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/leopardi" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_LEOPARDI.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask9" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">PASTIFICIO</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">LEOPARDI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask9)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>


        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/tenutab" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_TENUTABEDDINI.png');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask10" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">TENUTA</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">BEDDINI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask10)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>



        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/titouch" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_TITOUCH.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask11" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">TITOUCH</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask11)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>

        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/valledelsole" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_VALLEDELSOLE.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask12" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">VALLE</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">DEL SOLE</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask12)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>


        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/biancheto" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_BIANCHETO.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask13" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">FATTORIA</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">DEL BIANCHETO</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask13)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>




        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/mela" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_MELA.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask14" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">PASTICCERIA</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">MELA</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask14)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>




        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/alec" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/shooting3_AC.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask15" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                           
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">ALESSANDRA C</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask15)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>



        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/decimi" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_DECIMI.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask16" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">FRANTOIO</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">DECIMI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask16)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>


        <div class="col-6 col-md-4 p-3" >
            <a routerLink="/cerqueti" routerLinkActive="active">
                <svg style="background:url('../../assets/img/portfolio/intro_cerqueti.jpg');"
                    viewbox="0 0 50 50" width="300" height="300">
                    <defs>
                        <mask id="mask17" x="0" y="0" width="100" height="49">
                            <rect x="2%" y="2%" width="96%" height="96%" fill="#fff" />

                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="90%" dy="0">AGRITURISMO</text>
                            <text class="svg-tit" x="50%" id="ltrV" text-anchor="middle" y="97%" dy="0">CERQUETI</text>

                        </mask>
                    </defs>
                    <rect x="2%" y="2%" width="96%" height="96%" mask="url(#mask17)" fill-opacity="0.9" fill="#000" />
                </svg>
            </a>
        </div>


    </div>



    <div class="footer-cont mt-200 w-100">

        <app-footer></app-footer>

    </div>
</div>