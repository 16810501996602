import { Component, OnInit } from '@angular/core';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    AOS.init({
      duration: 3000
    });

    window.scrollTo(0, 0);

    var menu = document.getElementById('navi-toggle') as HTMLInputElement;
    if(menu.checked == true){
      menu.checked = false;
    }
   
  }

}
